*,
*:after,
*:before {
  box-sizing: inherit;
}
body {
  display: flex;
  justify-content: center;
  padding: 0;
  background-color: #fcfcfc;
  min-height: 100vh;
  font-size: 16px;
  line-height: 1.4;
  font-family: 'Montserrat', sans-serif;
  color: #372717;
  box-sizing: border-box;
  overflow-y: scroll;
}
.accordion {
  width: 100%;
  max-width: 760px;
}
.panel {
  background-color: #f0ebe1;
}
.panel__label {
  position: relative;
  display: block;
  width: 100%;
  background: none;
  border: none;
  text-align: left;
  padding: 10px 60px 10px 25px;
  font-weight: 500;
  font-size: 17px;
  font-family: inherit;
  transition: color 0.2s linear;
  cursor: pointer;
}
.panel__label:focus {
  outline: none;
}

.panel__label *{
  width: 100%;
}

.panel[aria-expanded='true'] .panel__content {
  opacity: 1;
}
.panel[aria-expanded='true'] .panel__label {
  color: #957029;
}
.panel[aria-expanded='true'] .panel__label:before {
  transform: rotate(0deg);
}
.panel__inner {
  overflow: hidden;
  will-change: height;
  transition: height 0.4s cubic-bezier(0.65, 0.05, 0.36, 1);
}
.panel__content {
  margin: 5px 25px 25px;
  font-size: 14px;
  color: #756658;
  opacity: 0;
  transition: opacity 0.3s linear 0.18s;
}
.panel:not(:last-child) {
  margin-bottom: 3px;
}

.city-options {
  padding: 5px;
}

.multi-city, .one-city {
  height: 110px;
  display: flex;
  justify-content: space-evenly;
}

.multi-city .city-column{
  width: 100%;
  text-align: center;
}

.panel-now .field-form input {
  outline: none;
  border: 0;
  border-radius: 4px;
  background: #fff;
  width: 250px;
  padding: 8px 10px;
  z-index: 200;
}

.field-form label{
  text-align: center;
  display: block;
  width: 150px;
  margin: 3px auto;
}

.text-center {
  text-align: center;
}

.city-frame {
  height: 350px;
}

.city-column {
}


.panel-active .panel__label{

}

.accordion {
  position: relative;
}

.panel-passive .panel__label{
  color: #ffffff;
  background: #0166bf;
}

.panel {
  background: #d7e6f4;
}

.css-1pcexqc-container {
  width: 175px;
  z-index: 999;
}

.multi-city .css-1pcexqc-container {
  margin-left: 10%;
}

.panel-now .css-1pcexqc-container:last-child{
  position: absolute;
  z-index: 100;
  background: #ffffff;
}

.panel-now .css-15k3av{
  position: absolute;
  z-index: 101;
  background: #ffffff;
}



.accept_data {
  color: #0d66bf;
  background: #d7e6f4;
  border: 2px solid #0d66bf;
  margin: 5px;
  padding: 8px;
  font-size: 14px;
  transition: 0.5s;
  cursor: pointer;
}

#footer {
  color: #ffffff;
  background: #0166bf;
  position: fixed;
  width: 100%;
  bottom: 0;
}
#footer p{
  font-size: 0.8em;
  margin: 3px 3px;
}

.t-left {
  display: inline-block;
  text-align: left;
}
.t-right {
  display: inline-block;
  text-align: right;
}

.c-option {
  background: #ffffff;
}

.accept_data:hover {
  background: #0d66bf;
  color: #fff;
}

@media (max-width: 500px) {
  .multi-city .css-10nd86i {
    width: 130px;
    margin-left: 7%;
  }

  .field-form label {
    text-align: center;
    display: block;
    width: 100px;
  }
}

#table-info {
  border-spacing: 0px;
  border-collapse: collapse;
  width: 60%;
  margin: 0 auto;
  text-align: center;
  min-width: 320px;
}


.light-row {
  background: #accdec;
  color: #000;
  padding: 5px;
}

.dark-row {
  background: #fafafa;
  color: #000;
  padding: 5px;
}


/*
spiner
*/
@keyframes lds-spinner {
  0 % {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes lds-spinner {
  0 % {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.lds-spinner {
  position: relative;
}
.lds-spinner div {
  position: absolute;
  -webkit-animation: lds-spinner linear 1s infinite;
  animation: lds-spinner linear 1s infinite;
  background: #1d3f72;
  width: 3px;
  height: 2px;
  border-radius: 19%;
  -webkit-transform-origin: 0px 10px;
  transform-origin: 0px 10px;
}
.lds-spinner div:nth-child(1) {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-animation-delay: -0.916666666666667s;
  animation-delay: -0.916666666666667s;
}
.lds-spinner div:nth-child(2) {
  -webkit-transform: rotate(30deg);
  transform: rotate(30deg);
  -webkit-animation-delay: -0.833333333333333s;
  animation-delay: -0.833333333333333s;
}
.lds-spinner div:nth-child(3) {
  -webkit-transform: rotate(60deg);
  transform: rotate(60deg);
  -webkit-animation-delay: -0.75s;
  animation-delay: -0.75s;
}
.lds-spinner div:nth-child(4) {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  -webkit-animation-delay: -0.666666666666667s;
  animation-delay: -0.666666666666667s;
}
.lds-spinner div:nth-child(5) {
  -webkit-transform: rotate(120deg);
  transform: rotate(120deg);
  -webkit-animation-delay: -0.583333333333333s;
  animation-delay: -0.583333333333333s;
}
.lds-spinner div:nth-child(6) {
  -webkit-transform: rotate(150deg);
  transform: rotate(150deg);
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s;
}
.lds-spinner div:nth-child(7) {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  -webkit-animation-delay: -0.416666666666667s;
  animation-delay: -0.416666666666667s;
}
.lds-spinner div:nth-child(8) {
  -webkit-transform: rotate(210deg);
  transform: rotate(210deg);
  -webkit-animation-delay: -0.333333333333333s;
  animation-delay: -0.333333333333333s;
}
.lds-spinner div:nth-child(9) {
  -webkit-transform: rotate(240deg);
  transform: rotate(240deg);
  -webkit-animation-delay: -0.25s;
  animation-delay: -0.25s;
}
.lds-spinner div:nth-child(10) {
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg);
  -webkit-animation-delay: -0.166666666666667s;
  animation-delay: -0.166666666666667s;
}
.lds-spinner div:nth-child(11) {
  -webkit-transform: rotate(300deg);
  transform: rotate(300deg);
  -webkit-animation-delay: -0.083333333333333s;
  animation-delay: -0.083333333333333s;
}
.lds-spinner div:nth-child(12) {
  -webkit-transform: rotate(330deg);
  transform: rotate(330deg);
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}
.lds-spinner {
  -webkit-transform: translate(-100px, -100px) scale(1) translate(100px, 100px);
  transform: translate(-100px, -100px) scale(1) translate(100px, 100px);
}


@media (max-width: 400px) {


    .css-1pcexqc-container {
        width: 150px;
        z-index: 999;
    }

    .multi-city .css-1pcexqc-container {
        margin-left: -2%;
    }
}

